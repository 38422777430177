/* _FOOTER.SCSS
 * --------------------------------------------------
 *  Footer styles should be declared here
 * -------------------------------------------------- */

footer {

    background: $white;
    color: $blue;
    padding: 60px;
    height: 100%;
    box-shadow: 0px 0px 42px -32px rgba(0,0,0,0.75);
    text-align: center;
    @include fontsize(14px);
    -webkit-clip-path: polygon(0 9px, 0 100%, 100.53% 100%, 100.46% 24px);
        clip-path: polygon(0 9px, 0 100%, 100.53% 100%, 100.46% 24px);

    @include bp-normal {
        padding: 60px 0 0 0;
        height: auto;
    }

    a {
        color: $blue;
        font-weight: bold;
        &:hover {
            color: $black;
        }
    }

}

.fleft {
    display: none;
    @include bp-medium {
        display: block;
        position: fixed;
        bottom: 0;
        left: -50px;
        height: 150px;
        width: auto;
        z-index: 1000;
    }
}