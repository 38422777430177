/* _BUTTONS.SCSS
 * --------------------------------------------------
 *  Button styles should be declared here
 * -------------------------------------------------- */

// default cta button
.btn {
    display: block;
    padding: 16px 20px;
    color: $white;
    text-align: center;
    background: $main-color;
    border-radius: 3px;
    transition: background .2s $cubic, transform .2s $cubic;
    
    &:hover {
        background: lighten($main-color, 5%);
        transform: translateY(-1px);
    }

    &:nth-of-type(2) {
        margin-top: 8px;
    }

    @include bp-small {
        display: inline-block;
        margin-right: 16px;

        &:nth-of-type(2) {
            margin-top: 0;
        }
    }
}

.btn__small {
    @include fontsize(14px);
}

.btn__github {
    padding: 2px 16px;
    line-height: 26px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .9);
    color: #24292e;
    border: 1px solid rgba(27,31,35,.2);
    border-radius: .25em;
    display: inline-block;
    font-family: $ovp-regular;
    @include fontsize(12px);
    vertical-align: top;
    background-color: #eff3f6;
    background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
    background-position: -1px -1px;
    background-repeat: repeat-x;
    background-size: 110% 110%;

    &:hover {
        background-color: #e6ebf1;
        background-image: linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%);
        background-position: -.5em;
        border-color: rgba(27,31,35,.35);
    }

    &:active {
        background-color: #e9ecef;
        background-image: none;
        border-color: rgba(27,31,35,.35);
        box-shadow: inset 0 0.15em 0.3em rgba(27,31,35,.15);
    }

    &:first-of-type {
        margin-right: 8px;
    }

    @include bp-medium {
        @include fontsize(14px);
        padding: 3px 16px;
    }
}

.btn-sm {
    color: $brown;
    background: rgba($white,0.5);
    padding: 15px 20px;
    margin: 10px 10px 10px 0;
    border: 2px solid $brown;
    transition: .8s $cubic;
    @include fontsize(14px);

    &:hover {
        color: $black;
        border: 2px solid $black;
        transition: .8s $cubic;
        
    }
}