/* _MIXINS.SCSS
 * --------------------------------------------------
 *  SASS mixins
 * 
 *  All mixins should be declared here. Included:
 *  vertical/horizontal gradient, truncate, 
 *  fontsize and breakpoints 
 * -------------------------------------------------- */

 // top to bottom gradient
 @mixin verticalGradient($top, $bottom){
    background: $top; //fallback for old browsers
    background: -webkit-linear-gradient(top,  $top 0%, $bottom 100%); 
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%);    
    background: linear-gradient(to bottom,  $top 0%, $bottom 100%); 
}

// left to right gradient
@mixin horizontalGradient($left, $right){
    background: $left; //fallback for old browsers
    background: -webkit-linear-gradient(left,  $left 0%, $right 100%); 
    background: -moz-linear-gradient(left,  $left 0%, $right 100%);    
    background: linear-gradient(to right,  $left 0%, $right 100%); 
}

// truncate
@mixin truncate($max-width) {
    max-width: $max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} 

// fontsize (calculates rem for given px)
@mixin fontsize($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// breakpoints
@mixin bp-small {
    @media (min-width: $bp-small) {
        @content;
    }
}

@mixin bp-medium {
    @media (min-width: $bp-medium) {
        @content;
    }
}

@mixin bp-normal {
    @media (min-width: $bp-normal) {
        @content;
    }
}

@mixin bp-large {
    @media (min-width: $bp-large) {
        @content;
    }
}

@mixin bp-xlarge {
    @media (min-width: $bp-xlarge) {
        @content;
    }
}

// Style to demo initial-letter
// ============================
$font-size: 1.15rem;
$cap-size: $font-size * 6.25;

// Wrap in @supports with `not` operator
@supports (not(initial-letter: 5)) and (not(-webkit-initial-letter: 5)) {
	.paragraph-section::first-letter {
		font-size: $cap-size;
		float: left;
		line-height: .7;
        margin: 10px 2px 0 0;
	}
}

// Progressive enhancements for supported browsers
@supports (initial-letter: 5) or (-webkit-initial-letter: 5) {
	.paragraph-section:nth-of-type(1)::first-letter {
		-webkit-initial-letter: 3;
		initial-letter: 3;
	}
}