/* _BASE.SCSS
 * --------------------------------------------------
 *  Base styles
 * 
 *  Default styles of the project, these will not
 *  be changed or altered in most of the cases
 * -------------------------------------------------- */

html {
    box-sizing: border-box;
    overflow: hidden;
    height:100%;
    border: 5px solid $white;
    @include bp-normal {
        border: 25px solid $white;
    }
}


*, *:before, *:after {
    box-sizing: inherit;
}

html, body {
    height: 100%;
    overflow-x: hidden;
}

body {

    font-family: $ovp-light;
    color: $text-color;
    background-image: $background-image2;
    background-color: $white;
    background-size: contain;
    margin: auto;
    box-shadow: inset 0px 0px 28px -14px rgba(0,0,0,0.75);

    @include fontsize(16px);

    @include bp-medium {
        @include fontsize(18px);
    }

}

.transition-fade {
    transition: 0.8s $cubic;
    opacity: 1;
  }
  
  html.is-animating .transition-fade {
    transition: 0.3s $cubic;
    opacity: 0;
    transform: translateY(210px); 
  }
  

// remove all margins
h1, h2, h3, h4, p {
    margin: 0;
    font-weight: normal;
}

// default font sizes (personal pref)
h1 {
    font-family: $ovp-regular;
    @include fontsize(32px);

    @include bp-medium {
        @include fontsize(48px);
    }
    
    &.big__heading {
         font-family: $ovp-light;
        @include fontsize(48px);

        @include bp-medium {
            @include fontsize(64px);
        }
    }
}

h2 {
    font-family: $ovp-light;
    @include fontsize(24px);

    @include bp-medium {
        @include fontsize(32px);
    }

    &.white-block {
        display: block;
        margin: 0px auto 33px;
        background-color: $white;
        width: 33.33333%;
        padding: 10px;
        box-shadow: 0px 0px 42px -22px rgba(0,0,0,0.75);
        text-align: center;
    }
}

h3 {
    font-family: $ovp-regular;
    @include fontsize(15px);
    color: $brown;
    text-align: center;
    padding: 20px 0 0 0;

    @include bp-medium {
        padding: 10px 0 0 0;
        @include fontsize(24px);
    }
}

h4 {
    font-family: $ovp-bold;
    @include fontsize(18px);  
    
    @include bp-medium {
        @include fontsize(21px);
    }  
}

p {
    color: $paragraph-color;
    line-height: 1.5;
}

section {
    z-index: 0;
    &#grey {
        margin-top: .5rem;
        background: transparent;
        padding: 0 0 50px 0;
    }
    &#white {
        background-color: $white-section;
    }
}

.small {
    @include fontsize(14px);
}

.thin {
    font-family: $ovp-thin;
}

.bold {
    font-weight: bold;
}

// 'normalize' some other elements (personal pref)
a {
    text-decoration: none;
    color: $brown;
    transition: 0.6s $cubic;

    &:hover {
        color: $black;
        transition: 0.6s $cubic;
    }
}

a:active,a:focus {
    outline:none;
}

img {
    width: 100%;
    display: block;
}

ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

section, footer {
    padding: 5px 0;
    position: relative;
    
    @include bp-normal {
        padding: 5px 0;
    }
}


input, textarea {
    width: 100%;
    padding: 12px;
    color: $text-color;
    outline: 0;
    border: 1px solid $border-color;    

    @include bp-medium {
        padding: 16px;
    }
}

input[type="email"]:focus, 
input[type="tel"]:focus,
input[type="text"]:focus, 
textarea {
    color: $main-color;
}

input[type="submit"] {
    cursor: pointer;
}

fieldset {
    padding: 0;
    border: none;
}

::-webkit-input-placeholder {
    color: $paragraph-color;    
}

::-moz-placeholder {
    color: $paragraph-color;    
}

:-ms-input-placeholder {
    color: $paragraph-color;    
}

:-moz-placeholder {
    color: $paragraph-color;    
}

// wrapper
.wrapper {
    padding: 0;
    max-width: 960px;
    margin: 0 auto;
    background: $white;

    @include bp-normal {
        padding: 0 48px;
    }
}

.container {
    max-width: none;
    padding: 0 !important;
}

.wrapper--big {
    max-width: 1400px;
}

.wrapper--big-second {
    max-width: 1300px;
    @include bp-normal {
        border-radius: 0 0 20px 20px;
    }
}

// clearfix: add 'cf' to container of floated elements
.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

// selected text color and bg
::-moz-selection {
    color: $white;
    background: $main-color;
}

::selection {
    color: $white;
    background: $main-color;
}

// this element is visible when visitor uses IE10 or below
.browserupgrade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9999;
    background: $white;
    padding: 16px;

    @include bp-medium {
        padding: 20px;
    }
    
    a {
        @extend %link;
    }
}

.paragraph-section {
    position: relative;
    border-radius: 10px;
    text-align: justify !important;
    padding: 4rem 1rem;

    &::first-letter {
        color: $brown;
    }   
}


.text-warning {
    color: $brown !important;
}
