/* _TEST.SCSS
 * --------------------------------------------------
 *  Styles for the test page
 * -------------------------------------------------- */

form {
    background: $white;
    padding: 20px 32px;
    position: relative;
    border-radius: 20px;
    display: block;
    margin: auto;
    max-width: 1200px;
    input, textarea {
        background: $white;
        border: none;
        border-bottom: 1px dashed $shadow-color;

    }

    textarea {
        min-height: 300px;
    }

    #sent {
        margin-top: 50px;
        @include fontsize(20px);
        height: 200px;
        transition: 1s $cubic;

        .fa-check-circle {
            @include fontsize(50px);
            margin-bottom: 30px;
            transition: 2s $cubic;
        }
    }

    h3 {
        padding: 0;
    }

    @include bp-medium {
        padding: 64px 80px;
    }
    
    p {
        margin-bottom: 32px;

        @include bp-medium {
            margin-bottom: 64px;        
        }

        text-align: center;
    }
}

.form__row {

    @include bp-small {
        width: 100%;
    }

}

.submit__row {
    position: absolute;
    left: 50%;
    top: 98%;

    transform: translate(-50%, -50%);
        @include bp-small {
        float: left;
        width: 30%;
    }

    input {
        background: $main-color;
        color: $white;
        border: 1px solid $main-color;
        border-top: none;
        transition: .2s $cubic;

        &:hover {
            border-color: lighten($main-color, 5%); 
            background: lighten($main-color, 5%);
        }

        @include bp-small {
            border-left: none;
            border-top: 1px solid $main-color;
        }
    }
}

.card__container {
    background: $white;
    padding: 50px;
    border-radius: 10px;
    box-shadow: 0px 20px 44px -32px $paragraph-color;
    transform: rotate(0.3deg);

    @include bp-normal {
        display: flex;
        flex-wrap: wrap;
    }
}

.card__item {
    display: flex;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 32px;
    padding-bottom: 24px;
    position: relative;
    @include bp-small {
        // flex-direction: column !important;
    }

    &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
    }
    
    &:nth-child(even) {
        flex-direction: row-reverse;
        .card__content {
                transform: translate(-80%, -50%);
        }
    }

    &:nth-child(odd) {
        .card__content {
                transform: translate(-14%, -50%);
    
        }
    }

    @include bp-normal {
        padding-bottom: 0;
        border: none;
        margin-bottom: 15px;
        
        &:nth-of-type(5) {
            margin-bottom: 15px;
        }
    }

    &:hover {
        .image {
            transform: scale(1.1) translateZ(0);
            transition: .8s $cubic;
        }

        h3 {
            color: $black;
            transition: .8s $cubic;
        }

    }
}

.card__image {
    border: none;
    transition: .8s $cubic;   
    transform: scale(1) translateZ(0);
    overflow: hidden; 
    height: 100%;
    border: 5px solid #eee;
    position: relative;
    
    @include bp-normal {
        width: 100%;              
    }

    @include bp-small {
        width: 100%;              
    }
}

.image {
    position: relative;
    height: 400px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
    transition: .8s $cubic;
    border: 5px solid $white;
    box-shadow: 0 2px 1px hsla(0,0%,100%,.2),
                0 -1px 1px 1px hsla(0,0%,0%,.4),
                inset 0 2px 3px 1px hsla(0,0%,0%,.2),
                inset 0 4px 3px 1px hsla(0,0%,0%,.2),
                inset 0 6px 3px 1px hsla(0,0%,0%,.1);
    
}

.card__content {     
    padding: 20px 0;    
    border-radius: 0 0 10px 10px;
    transition: .8s $cubic;
    position: absolute;
    left: 50%;
    top: 50%;  

    @include bp-small {
        position: relative;
        left: auto;
        top: auto;
        transform: translate(0, 0) !important;
    }    
    a {
        color: $brown;
    }

    h3 {
        transition: .8s $cubic;
        @include fontsize(17px);
        font-family: $ovp-light;
    }

    p {
        color: $paragraph-color;  
    }

    .card__excerpt, .card__title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 50%;

    }
}

