/* MAIN.SCSS
 * --------------------------------------------------
 *  Import all seperate files 
 *  https://sass-guidelin.es/#the-7-1-pattern
 * 
 *  Note: To support fa-regular and fa-brand icons,
 *  add them to the main override in bower.json
 *  and execute 'gulp wiredep' to inject the assets
 * -------------------------------------------------- */
// import utils
@import 'utils/variables';
@import 'utils/functions';
@import 'utils/mixins';
@import 'utils/placeholders';
@import 'utils/helpers';

// import assets from bower_components - automatically injected 
// bower:scss
@import "bower_components/font-awesome/scss/fontawesome.scss";
@import "bower_components/font-awesome/scss/regular.scss";
@import "bower_components/font-awesome/scss/solid.scss";
@import "bower_components/font-awesome/scss/brands.scss";
// endbower

// import base
@import 'base/fonts';
@import 'base/base';

// import partials
@import 'partials/buttons';
@import 'partials/grid';
@import 'partials/nav';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/polygon';
@import 'partials/bootstrap';

// import pages
@import 'pages/index';
@import 'pages/styleguide';
@import 'pages/pages';