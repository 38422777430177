.navbar {
    display: block;
    margin: auto;
    text-align: center;
    z-index: 100;
    background-color: $white;
    // box-shadow: 0 22px 39px -31px $black;
    border-bottom: 1px dashed #EEE;
    padding: 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    position: absolute;
    left: 50%;
    top: 86%;
    transform: translate(-50%, -4%);

    @include bp-large {
        top: auto;
        right: auto;
        position: relative;
        width: 80%;
        left: 10px;
        transform: none;
        border-top:1px dashed #EEE;
    }
}
.navbar-nav {
    align-items: center;
}

.navbar-nav .dropdown-menu {
    position: absolute;
    border: 0;
    box-shadow: 24px 5px 66px -32px $paragraph-color;
    transition: 0.2s $cubic;
}

.dropdown-item {
    color: $paragraph-color;
    transition: 0.2s $cubic;
    padding: 10px 20px;    
}

.dropdown-item.active, .dropdown-item:active {
    background-color: $brown;
    transition: 0.6s $cubic;
    color: $white;
}

.dropdown-item.active, .dropdown-item:hover {
    background-color: $brown;
    transition: 0.6s $cubic;
    color: $white;
}

.navbar-light .navbar-nav .nav-link {
    text-align: center;
    width: 100%;
    height: 50px;
    padding: 20px 20px;
    @include bp-medium {
        padding-left: 15px !important;
    }
    @include bp-large {
        padding-left: 0 !important;
    }
}

.nav-link {
    color: $brown !important;
    transition: 0.6s $cubic;
    text-align: center;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: $blue !important;
    transition: 0.6s $cubic;
}

.navbar-light .navbar-toggler {
    color: $brown !important;
    border-color: $brown !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
}
.navbar-light .navbar-nav .nav-link {
    padding-left: 20px;
}
