.polygon-top {
    display: none;
    @include bp-medium {
        display: block;
        background:$white;
        -webkit-clip-path: polygon(0 26px, 5px 51px, 100.46% 42px, 100.7% 34px);
        clip-path: polygon(0 26px, 5px 51px, 100.46% 42px, 100.7% 34px);
        z-index: 5;
        position: absolute;
        left: 0;
        top: -35px;
        width: 100%;
        height: 35px;               
    }
}

.polygon-bottom {   
    display: none;
    @include bp-medium {
        display: block;
        background:$grey-section;
        -webkit-clip-path: polygon(0px 1px, 0px 4px, 100% 20px, 100% 0);
        clip-path: polygon(0px 1px, 0px 4px, 100% 20px, 100% 0);
        z-index: 5;
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 35px;    
        box-shadow: inset 0px 0px 42px -22px rgba(0,0,0,0.75);              
    }
}