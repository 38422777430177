/* _HELPERS.SCSS
 * --------------------------------------------------
 *  Helper/modifier classes
 * 
 *  Most of it follows BEM rules, keeps CSS DRY
 * -------------------------------------------------- */

 //flex
.d-flex {
    display: flex;
}

// vertical centering content
.vh__center {
    display: table;
    width: 100%;
    height: 100%;
}

.vh__center--sm {
    height: 60%;
}

.wrap {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

// default cta
.cta__link {
    @extend %link;
}

// color changer
.section-white {
    background-color: $white;
}

//margin/padding defaults
.mb-xs {
    margin-bottom: 4px;
        
    @include bp-medium {
        margin-bottom: 8px;
    }
}

.mb-sm {
    margin-bottom: 8px;
        
    @include bp-medium {
        margin-bottom: 12px;
    }
}

.mb-md {
    margin-bottom: 24px;
        
    @include bp-medium {
        margin-bottom: 32px;
    }
}

.m-lg {
    margin: 50px auto;
}

.mt-md {
    margin-top: 24px;
        
    @include bp-medium {
        margin-top: 32px;
    }
}

.pt-sm {
    padding-top: 8px;

    @include bp-medium {
        padding-top: 12px;
    }
}


.pt-0 {
    padding-top: 0;
}

.mr-1 {
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

.ml-3 {
    margin-left: 30px !important;
}

// borders
.bdr-btm {
    border-bottom: 1px solid $border-color;
}