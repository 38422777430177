/* _HEADER.SCSS
 * --------------------------------------------------
 *  Header styles should be declared here
 * -------------------------------------------------- */
header {

    background: $white;
    background-size: cover;
    box-shadow: 0px 20px 44px -32px $paragraph-color;
    margin-top: 0 !important;
    border-radius: 0;

    @include bp-xlarge {
        border-radius: 20px 20px 0 0;
        margin-top: 20px !important;
    }

    &.wrapper {
        position: relative;
        max-width: 1300px;
        // transform: rotate(-0.5deg);
        
        @include bp-medium {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: space-around;
        }
    }
    
    h1 {
        color: $blue;
        @include bp-normal {
            padding: 10px 0;
        }
    }

    h3 {
        color: $paragraph-color;
        @include fontsize(18px);
        font-family: $ovp-light;
    }

    h2 {
        padding: 0 0 20px 0;
        color: $brown;
    }

    hr {
        width: 70%;
    }

    p {
        color: $white;
        margin-bottom: 8px;
    }

    i {
        color: $secondary-color;
        margin-left: 4px;
    }

    .header__text {
        width: 100%;
        position: relative;
        padding: 20px;
        text-align: center;
        left: 0;
        @include bp-small {
            width: 100%;
            padding: 20px 0 30px 0;    
            left: 0; 
        }
        @include bp-normal {
            left: 20px; 
            width: 55%;           
        }
    }

    .header__picture {
        position: relative;
        height: 350px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: $black;
        transform: rotate(360deg);
        opacity: 0.9;
        @include bp-medium {
            display: none;
        }
        @include bp-normal {
            display: block;
            width: 45%; 
            box-shadow: 0px 0px 10px 0 $paragraph-color;
            left: 0;
            // margin: 20px 0;
            // border-radius: 20px 20px 0 0;
        }
    }
}

.header__border {
    height: 16px;

     @include bp-normal {
        height: 24px;
    }
}

.clicks {
    margin-bottom: 0;
    color: $white;
    display: none;

    @include bp-medium {
        &.is-active {
            display: block;
        }
    }
}

.clicks--index {
    @extend .clicks;
    
    @include fontsize(16px);
    color: darken($secondary-color, 32%);
    background-color: lighten($secondary-color, 24%); 
    padding: 8px 12px;
    border-radius: 16px;

    @include bp-medium {
        transform: translateY(-8px);

        &.is-active {
            display: inline-block;
        }
    }
}