.carousel {
  max-width: 1300px;
  margin: auto;
}

.carousel-control-next-icon:after
{
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f35a";
  font-size: 25px;
  color: $brown;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.carousel-control-prev-icon:after {
  font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f359";
  font-size: 25px;
  color: $brown;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.carousel-inner {
  padding: 20px 0 0 0;
  background: $white;
  margin: auto;
  @include bp-medium {
    padding: 50px 50px 0 50px;
  }
}

.carousel-image {
  padding: 30px;
  display: block;
  margin: auto;
  height: 396px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @include bp-medium {
    height: 600px;
  }
}

.carousel-describe {
  text-align: center;
  padding: 10px 0 0 0 ;
    @include bp-medium {
      padding: 30px 0 0 0;
    }
}

#custCarousel .carousel-indicators {
  position: static;
  margin-top: 20px;
  display: none;
  @include bp-medium {
    display: flex;
    flex-wrap: wrap;
  }
}

#custCarousel .carousel-indicators li .carousel-thumb {
  opacity: 1;
  height: 60px;
  background-size: cover;
  background-position: center;
  transition: 0.8s $cubic;
}
.imagoMundi2 {
  width: 60px;
}

.carousel-indicators li {
  width: 60px;
  height: 60px;
}

#custCarousel .carousel-indicators li.active .carousel-thumb {
  opacity: 1;
  transition: 0.9s $cubic;
}

#custCarousel .carousel-indicators li:hover .carousel-thumb {
  opacity: 1;
  transition: 0.6s $cubic;
}
