/* _VARIABLES.SCSS
 * --------------------------------------------------
 *  SASS variables
 * 
 *  Colors, fonts, breakpoints, transitions and 
 *  other variables should be declared here
 * -------------------------------------------------- */
//background image base

$background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23eeeeee' fill-opacity='0.25' fill-rule='evenodd'/%3E%3C/svg%3E");
$background-image2: url('../../images/bkg/rrr.jpg');
// change default fa-font-path to work with this project 
// info on Font Awesome 5: (https://goo.gl/d5Ua5B)
$fa-font-path: '../fonts' !default;

// colors
$white: rgb(255, 255, 247);
$black: #000000;
$blue: #2b3544;
$brown: #436061;

$main-color: #e74c3c;
$secondary-color: #F9BF3B;
$tertiary-color: #ff7f50;

$text-color: #181818;
$paragraph-color: #999999;
$shadow-color: #cccccc;
$border-color: #eeeeee;
$background-color: #fafafa;

$success-color: #29b572;
$error-color: #e74c3c;


// fonts
$ovp-regular: 'Overpass-Regular', sans-serif;
$ovp-bold: 'Overpass-Bold', sans-serif;
$ovp-light: 'Overpass-Light', sans-serif;
$ovp-thin: 'Overpass-Thin', sans-serif;

// breakpoints
$bp-small: 480px;
$bp-medium: 768px;
$bp-normal: 960px;
$bp-large: 1200px;
$bp-xlarge: 1500px;

// transitions
$cubic: cubic-bezier(.64,.09,.08,1);

//section
$white-section: #ffffff;
$grey-section: #f1ebdb;

